import React, { useState, useCallback } from "react";
import "./Investors.css";
import Slides from "../features/investorsPage/slides";
import InvestorFooter from "../features/home/investorFooter";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import Auth from "../utils/auth";

function Investors() {
  const [pptOpen, setPptOpen] = useState("slides");
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);

    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <div className='investorsContainer'>
      <Particles
        id='tsparticles1'
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          style: {
            position: "absolute",
            height: "100%",
            width: "100vw",
            zIndex: "0",
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              onDiv: {
                selectors: "#repulse-div",
                enable: true,
                mode: "repulse",
                type: "rectangle",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 75,
                duration: 0.2,
              },
            },
          },
          particles: {
            color: {
              value: "#1f2c5c",
            },
            links: {
              color: "#1f2c5c",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              directions: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 70,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      />
      <h2>Investment Details</h2>
      {Auth.loggedIn() ? (
        <div className='investorBody'>
          <Slides pptOpen={pptOpen} setPptOpen={setPptOpen} />
        </div>
      ) : (
        <div className='investorBody'>
          <h5>You must be logged in to view this information</h5>
        </div>
      )}
      <InvestorFooter />
    </div>
  );
}

export default Investors;
